import React from "react";

import { Link } from "react-router-dom";

const cardContent = [
  {
    imgSrc: "/assets/s1.jpeg",
    altText: "S1",
    title:
      "Ambulante Begeleiding binnen de Jeugdzorg: Jongeren in hun Kracht Zetten",
    text: "Als begeleider binnen de jeugdzorg geloof ik sterk in de kracht van jongeren. Met ambulante begeleiding help ik hen ontdekken wat hun eigen krachten en mogelijkheden zijn. Ik bied ondersteuning op maat, afgestemd op de individuele behoeften van elke jongere en hun omgeving. Of het nu gaat om het versterken van sociale vaardigheden, het opbouwen van zelfvertrouwen of het vinden van balans in moeilijke situaties, mijn doel is altijd om jongeren in hun eigen kracht te zetten.",
  },
  {
    imgSrc: "/assets/s2.jpeg",
    altText: "S2",
    title: "Crisisinterventies: Snel Ingrijpen, Rust Creëren",
    text: "Wanneer jongeren of gezinnen in een crisissituatie belanden, is snel en doeltreffend ingrijpen essentieel. Met mijn ervaring in crisisinterventies binnen de jeugdzorg bied ik directe hulp wanneer dat het hardst nodig is. Of het nu gaat om plotselinge escalaties in gedrag, onverwachte gebeurtenissen binnen het gezin of andere acute situaties, ik zorg voor een veilige omgeving waarin rust kan terugkeren.",
  },
  {
    imgSrc: "/assets/s3.jpeg",
    altText: "S3",
    title:
      "Ervaringen op het Speciaal Onderwijs: Schaduwbegeleiding en 1-op-1 Ondersteuning",
    text: "Met jarenlange ervaring in het speciaal onderwijs heb ik intensieve 1-op-1 begeleiding mogen bieden aan jongeren met specifieke ondersteuningsbehoeften. Deze begeleiding helpt hen niet alleen om op school hun weg te vinden, maar ook om zich emotioneel en sociaal te ontwikkelen.",
  },
  {
    imgSrc: "/assets/s4.jpeg",
    altText: "S4",
    title: "Coaching voor Jongeren: Het Beste uit Jezelf Halen",
    text: "Coaching voor jongeren draait om hen te begeleiden bij het ontdekken van hun eigen mogelijkheden en het realiseren van hun doelen. Ik bied coachingstrajecten waarin jongeren leren omgaan met uitdagingen zoals faalangst, motivatieproblemen, onzekerheid of gedragsproblemen.",
  },
  {
    imgSrc: "/assets/s3.jpeg",
    altText: "S5",
    title: "Ondersteuning van Ouders: Samen Sterker voor je Kind",
    text: "In mijn werk met jongeren is de betrokkenheid van ouders van onschatbare waarde. Daarom bied ik ook begeleiding aan ouders die worstelen met de uitdagingen van het opvoeden van hun kind in complexe situaties.",
  },
  {
    imgSrc: "/assets/s2.jpeg",
    altText: "S6",
    title: "Begeleiding naar Zelfstandigheid: Klaar voor de Toekomst",
    text: "Voor jongeren is het een grote stap om zelfstandig te worden, vooral wanneer zij te maken hebben met obstakels zoals gedragsproblemen, leeruitdagingen of andere persoonlijke belemmeringen. Ik begeleid jongeren naar een toekomst waarin ze zelfstandig kunnen functioneren en hun eigen keuzes kunnen maken.",
  },
];

const App = () => {
  return (
    <React.Fragment>
      <div
        className="d-flex flex-column justify-content-center align-items-center bg-light"
        style={{ minHeight: "90vh" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-12 col-xl-10 text-center">
              <div className="logo-box mb-4 mx-auto">
                <img
                  className="w-100 object-fit-cover"
                  alt="Logo"
                  src="/assets/logo.png"
                />
              </div>
              <p className="lead my-4 text-muted">
                Welkom bij ons jeugdzorginitiatief,
                <br />
                waar we individuen empoweren en een betekenisvolle impact
                creëren door middel van
                <br />
                persoonlijke ondersteuning en begeleiding.
              </p>
              <Link to="/contact" className="btn btn-primary">
                Neem Contact Op
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-12 col-xl-10 text-center">
              <h1 style={{ fontWeight: 700 }}>Diensten</h1>
              <div className="row">
                {cardContent.map((card, index) => (
                  <div className="col-12 col-sm-6 col-md-4 my-3" key={index}>
                    <div className="card h-100 shadow-sm">
                      <img
                        src={card.imgSrc}
                        className="card-img-top"
                        alt={card.altText}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{card.title}</h5>
                        <p className="card-text text-muted">{card.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;
