import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import Contact from "./Contact";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<App />} />
      </Routes>
      <footer className="text-center bg-dark text-light py-3 mt-5">
        <p className="mb-0">
          &copy; {new Date().getFullYear()} Rijkaannzorg. All rights reserved.
        </p>
      </footer>
    </BrowserRouter>
  );
};

export default Routing;
